<template>
    <div class="coupon-preview d-flex justify-content-center">
        <div class="bg-light border rounded p-2">
            <svg id="barcode"
                 v-show="['EAN13', 'CODE128'].includes(couponType)">
            </svg>
            <canvas id="qrcode"
                    v-show="['qrcode', ].includes(couponType)">
            </canvas>
        </div>
    </div>
</template>

<script>
import * as JsBarcode from "jsbarcode"
import Qrious from "qrious";
export default {
    name: "CouponPreview",
    props: {
        "couponCode": {
            type: String,
        },
        "couponType": {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
    mounted () {
        if (["EAN13", "CODE128"].includes(this.couponType)) {
            if (this.couponCode) {
                JsBarcode(
                    "#barcode",
                    this.couponCode,
                    {
                        format: this.couponType,
                    }
                );
            }
        }

        if (["qrcode"].includes(this.couponType)) {
            new Qrious({
                element: document.getElementById("qrcode"),
                value: this.couponCode
            });
        }

    },
    watch: {
        couponType () {
            if (["EAN13", "CODE128"].includes(this.couponType)) {
                if (this.couponCode) {
                    JsBarcode(
                        "#barcode",
                        this.couponCode,
                        {
                            format: this.couponType,
                        }
                    );
                }
            }

            if (["qrcode"].includes(this.couponType)) {
                new Qrious({
                    element: document.getElementById("qrcode"),
                    value: this.couponCode
                });
            }
        },

        couponCode () {
            if (["EAN13", "CODE128"].includes(this.couponType)) {
                if (this.couponCode) {
                    JsBarcode(
                        "#barcode",
                        this.couponCode,
                        {
                            format: this.couponType,
                        }
                    );
                }
            }

            if (["qrcode"].includes(this.couponType)) {
                new Qrious({
                    element: document.getElementById("qrcode"),
                    value: this.couponCode
                });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.coupon-preview {

}
</style>
